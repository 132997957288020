// #region Import Used Components and Services
import { Injectable } from '@angular/core';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { constantUrl } from '../../../app/Shared/constantUrl' ;

// #endregion

@Injectable({
  providedIn: 'root'
})

export class LosService {
  private lastAppId = new BehaviorSubject<any>(null);
  private lastFilterDropdownValue = new BehaviorSubject<any>({});

  constructor(private _Route: Router, private http: HttpClient, public snackBar: MatSnackBar) {
  }
  setLastAppId(id: any) {
    this.lastAppId.next(id);
  }
  getLastAppId() {
    return this.lastAppId.value;
  }
  setFilterDropdownValue(data: any) {
    this.lastFilterDropdownValue.next(data);
  }
  getFilterDropdownValue(type: any) {
    return this.lastFilterDropdownValue.value[type];
  }



  // #region get Inquiry List
  public GetLosInquiryList(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetInquiryList`, requestData);
  }
  // #endregion

  // #region save new inquiry
  public saveNewInquiry(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveNEWEnquire`, requestData);
  }
  // #endregion

  // #region assign to inquiry
  public assignToInquiry(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveEnquireAssignment`, requestData);
  }
  // #endregion

  // #region reject inquiry
  public rejectInquiry(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_RejectEnquire`, requestData);
  }
  // #endregion

  // #region get lead source
  public getLeadSourceDropdown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetLeadSourceForDropdown`, requestData);
  }
  // #endregion

  // #region get branch
  public getBranchDropdown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Masters/GetBranchesDropdown`, requestData);
  }
  // #endregion

  // #region get employee
  public getEmployeeDropdown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Masters/GetEmployeeDropdown`, requestData);
  }
  // #endregion

  // #region get CRC List
  public GetLosCRCList(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetCRCList`, requestData);
  }
  // #endregion



  // #region complete crc process
  public LosCrcProcessComplete(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOSCompleteProcess`, requestData);
  }
  // #endregion

  // #region Upload crc doc
  public UploadCrcDoc(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpointUploadDoc}/api/UploadDoc/UploadApplicationDoc`, requestData);
  }
  // #endregion








  // #region LOS_SaveCustomerTVR
  public LOS_SaveCustomerTVR(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveCustomerTVR`, requestData);
  }
  // #endregion

  // #region LOS_GetCustomerTVRDetails
  public LOS_GetCustomerTVRDetails(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetCustomerTVRDetails`, requestData);
  }
  // #endregion 


  






  // #region LOS_GetApplicationValuationCheckingDetails
  public LOS_GetApplicationValuationCheckingDetails(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicationValuationCheckingDetails`, requestData);
  }
  // #endregion







  // #region LOS_SaveCreditAssignment
  public LOS_SaveCreditAssignment(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveCreditAssignment`, requestData);
  }
  // #endregion





  // #region LOS_GetApplicationDetailsForFileProcessing
  public LOS_GetApplicationDetailsForFileProcessing(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicationDetailsForFileProcessing`, requestData);
  }
  // #endregion











  // #region LOS_SearchApplication
  public LOS_SearchApplication(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SearchApplication`, requestData);
  }
  // #endregion

  // #region LOS_GetApplicationCharges
  public LOS_GetApplicationCharges(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicationCharges`, requestData);
  }
  // #endregion

  // #region LOS_SaveApplicationCharges
  public LOS_SaveApplicationCharges(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveApplicationCharges`, requestData);
  }
  // #endregion

  // #region LOS_GetApplicationProductChargesHeadsForDropdown
  public LOS_GetApplicationProductChargesHeadsForDropdown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicationProductChargesHeadsForDropdown`, requestData);
  }
  // #endregion

  // #region LOS_GetChargesHeadsAmountAutoFill
  public LOS_GetChargesHeadsAmountAutoFill(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetChargesHeadsAmountAutoFill`, requestData);
  }
  // #endregion

  // #region LOS_GetAppliactionReqDocForDropDown
  public LOS_GetAppliactionReqDocForDropDown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetAppliactionReqDocForDropDown`, requestData);
  }
  // #endregion

  // #region LOS_SaveAppliactionDocument
  public LOS_SaveAppliactionDocument(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveAppliactionDocument`, requestData);
  }
  // #endregion




  // #region LOS_GetApplicationDetails
  public LOS_GetApplicationDetails(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicationDetails`, requestData);
  }
  // #endregion

 

  // #region LOS_GetCourierForDropdown
  public LOS_GetCourierForDropdown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetCourierForDropdown`, requestData);
  }
  // #endregion

  

  // #region LOS_GetCourierListReceiveHO
  public LOS_GetCourierListReceiveHO(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetCourierListReceiveHO`, requestData);
  }
  // #endregion

 

  // #region LOS_GetCourierDetailsForReceving
  public LOS_GetCourierDetailsForReceving(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetCourierDetailsForReceving`, requestData);
  }
  // #endregion



  // #region LOS_GetFileCheckingHOList
  public LOS_GetFileCheckingHOList(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetFileCheckingHOList`, requestData);
  }
  // #endregion

 


  

  // #region LOS_Get_rptCAMReport
  public LOS_Get_rptCAMReport(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_Get_rptCAMReport`, requestData);
  }
  // #endregion

  // #region LOS_Get_rptCAMReport
  public GetStateDropdown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Masters/GetState`, requestData);
  }
  // #endregion

  // #region LOS_Get_rptCAMReport
  public GetDistrickDropdown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Masters/GetDistricts`, requestData);
  }
  // #endregion

  // #region LOS_Get_rptCAMReport
  public GetTehasilDropdown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Masters/GetTahsil`, requestData);
  }
  // #endregion

  


  // #region LOS_EmployeeMobileDeviceID
  public LOS_EmployeeMobileDeviceID(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_EmployeeMobileDeviceID`, requestData);
  }
  // #endregion

  // #region sendFCMnotification
  public sendFCMnotification(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/sendFCMnotification`, requestData);
  }
  // #endregion

  // #region Get_PartnerForDropdown
  public Get_PartnerForDropdown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Get_PartnerForDropdown`, requestData);
  }
  // #endregion

  // #region LOS_GetMyLeadsList
  public LOS_GetMyLeadsList(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetMyLeadsList`, requestData);
  }
  // #endregion

  // #region LOS_GetInquiryDetails
  public LOS_GetInquiryDetails(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetInquiryDetails`, requestData);
  }
  // #endregion

  // #region LOS_SaveRevertInquiry
  public LOS_SaveRevertInquiry(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveRevertInquiry`, requestData);
  }
  // #endregion

  // #region LOS_SaveRejectInquiry
  public LOS_SaveRejectInquiry(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveRejectInquiry`, requestData);
  }
  // #endregion

  // #region LOS_SaveHoldInquiry
  public LOS_SaveHoldInquiry(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveHoldInquiry`, requestData);
  }
  // #endregion


  // #region LOS_GetRejectedApplicationList
  public LOS_GetRejectedApplicationList(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetRejectedApplicationList`, requestData);
  }
  // #endregion

  // #region LOS_Save_ApplicationReOpen
  public LOS_Save_ApplicationReOpen(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_Save_ApplicationReOpen`, requestData);
  }
  // #endregion

  // #region LOS_rptApplicationStatusReport
  public LOS_rptApplicationStatusReport(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_rptApplicationStatusReport`, requestData);
  }
  // #endregion

  // #region LOS_GetApplicationAssetDetails
  public LOS_GetApplicationAssetDetails(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicationAssetDetails`, requestData);
  }
  // #endregion


  // #region LOS_GetCalculateEMIIRR
  public LOS_GetCalculateEMIIRR(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetCalculateEMIIRR`, requestData);
  }
  // #endregion


  // #region LOS_SaveGenerateApplicationNo
  public LOS_SaveGenerateApplicationNo(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveGenerateApplicationNo`, requestData);
  }
  // #endregion


 

  // #region LOS_GetApplicationDetailsForSaleProcess
  public LOS_GetApplicationDetailsForSaleProcess(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicationDetailsForSaleProcess`, requestData);
  }
  // #endregion

  // #region FinancialDetailsForUpdate
  public FinancialDetailsForUpdate(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/FinancialDetailsForUpdate`, requestData);
  }
  // #endregion


  // #region LOS_UpdateApplicationFinancialDetails
  public LOS_UpdateApplicationFinancialDetails(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_UpdateApplicationFinancialDetails`, requestData);
  }
  // #endregion


  // #region LOS_DeleteAssetDetail
  public LOS_DeleteAssetDetail(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_DeleteAssetDetail`, requestData);
  }
  // #endregion

  // #region LOS_SaveApplicationCustomerWEB
  public LOS_SaveApplicationCustomerWEB(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveApplicationCustomerWEB`, requestData);
  }
  // #endregion

  // #region LOS_SaveLoginFee
  public LOS_SaveLoginFeeweb(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveLoginFeeweb`, requestData);
  }
  // #endregion

  // #region LOS_DeleteApplicationCustomer
  public LOS_DeleteApplicationCustomer(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_DeleteApplicationCustomer`, requestData);
  }
  // #endregion

  // #region LOS_SaveAssetDetailsApp
  public LOS_SaveAssetDetailsApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_SaveAssetDetailsApp`, requestData);
  }
  // #endregion

  // #region LOS_CheckCustomerDuplicationKYCApp
  public LOS_CheckCustomerDuplicationKYCApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_CheckCustomerDuplicationKYCApp`, requestData);
  }
  // #endregion

  

  // #region Upload crc doc
  public UploadCustomerDoc(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpointUploadDoc}/api/UploadDoc/UploadCustomerDoc`, requestData);
  }
  // #endregion

  // #region LOS_ValidateSalesProcessApp
  public LOS_ValidateSalesProcessApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_ValidateSalesProcessApp`, requestData);
  }
  // #endregion
  // #region LOS_ValidateSalesProcessApp
  public LOS_CompleteProcessAPP(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_CompleteProcessAPP`, requestData);
  }
  // #endregion

  // #region LOS_SaveApplicationCustomerFirm
  public LOS_SaveApplicationCustomerFirm(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveApplicationCustomerFirm`, requestData);
  }
  // #endregion

  // #region Get_CustomerDetailsApp
  public Get_CustomerDetailsApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/Get_CustomerDetailsApp`, requestData);
  }
  // #endregion 

  // #region Get_CustomerDetailsApp
  public LOS_UpdateCustomerDetailsApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_UpdateCustomerDetailsApp`, requestData);
  }
  // #endregion

  // #region Get_FirmDetailsWebForUpdate
  public Get_FirmDetailsWebForUpdate(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Get_FirmDetailsWebForUpdate`, requestData);
  }
  // #endregion

  // #region LOS_GetCustomerAddressWeb
  public LOS_GetCustomerAddressWeb(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetCustomerAddressWeb`, requestData);
  }
  // #endregion

  // #region LOS_UpdateCustomerAddressWeb
  public LOS_UpdateCustomerAddressWeb(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_UpdateCustomerAddressWeb`, requestData);
  }
  // #endregion

  // #region LOS_GetSectionLatterDetailsViewModel
  public LOS_GetSectionLatterDetailsViewModel(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetSectionLatterDetailsViewModel`, requestData);
  }
  // #endregion

  // #region LOS_DeleteCustomerDocument
  public LOS_DeleteCustomerDocument(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_DeleteCustomerDocument`, requestData);
  }
  // #endregion

  // #region LOS_SaveCustomerKYCDocApp
  public LOS_SaveCustomerKYCDocApp(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_SaveCustomerKYCDocApp`, requestData);
  }
  // #endregion
  // #region LOS_GetLoginFeeSMS
  public LOS_GetLoginFeeSMS(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetLoginFeeSMS`, requestData);
  }
  // #endregion

  // #region LOS_Get_FIApplicationListWeb
  public LOS_Get_FIApplicationListWeb(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_Get_FIApplicationListWeb`, requestData);
  }
  // #endregion

  // #region LOS_GetLoginFeeReceipt
  public LOS_GetLoginFeeReceipt(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/ED/LOS_GetLoginFeeReceipt`, requestData);
  }
  // #endregion

  // #region LOS_GetSendBanckToBranchListWeb
  public LOS_GetSendBanckToBranchListWeb(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetSendBanckToBranchListWeb`, requestData);
  }
  // #endregion

  public longToShortUrl(requestData: any) {
    return this.http.post("https://goolnk.com/api/v1/shorten", requestData);
  }

  // #region GetHoldInquiryList
  public GetHoldInquiryList(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Masters/LOS_rptHoldInquiry`, requestData);
  }
  // #endregion

  // #region LOS_GetPendingDocApplicationListWEB
  public LOS_GetPendingDocApplicationListWEB(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_PendingDocApplication`, requestData);
  }
  // #endregion


  // #region LOS_GetPendingDocListWEB
  public LOS_GetPendingDocListWEB(requestData: any) {
    //return this.http.post(`${constantUrl.apiEndpoint}/api/Mobile/LOS_GetApplicationRequiredDocListApp`, requestData);
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicationRequiredDocListWEB`, requestData);
  }
  // #endregion

  // #region LOS_ApplicationFinalRejection
  public LOS_ApplicationFinalRejection(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_ApplicationFinalRejection`, requestData);
  }
  // #endregion

  // #region LOS_SaveApplicationCreditSoftApproval
  public LOS_SaveApplicationCreditSoftApproval(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveApplicationCreditSoftApproval`, requestData);
  }
  // #endregion

  // #region LOS_SaveApplicationDocument
  public LOS_SaveApplicationDocument(requestData: any) {   
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveAppliactionDocument`, requestData);
  }
  // #endregion

  // #region LOS_GetApplicationRequiredDocListWEB
  public LOS_GetApplicationRequiredDocListWEB(requestData: any) {   
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicationRequiredDocListWEB`, requestData);
  }
  // #endregion
 
  // #region LOS_GetLoginFee_Receipt
  public LOS_GetLoginFee_Receipt(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetLoginFee_Receipt`, requestData);
  }
  // #endregion

  // #region IRRcalculation
  public IRRcalculation(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/IRRcalculation`, requestData);
  }
  // #endregion

  // #region LOS_SearchApplicationByReceiptEntry
  public LOS_SearchApplicationByReceiptEntry(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SearchApplicationByReceiptEntry`, requestData);
  }
  // #endregion

  // #region LOS_SaveAppliactionDocumentJson
  public LOS_SaveAppliactionDocumentJson(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveAppliactionDocumentJson`, requestData);
  }
  // #endregion

  // #region LOS_GetApplicationDocAttachment
  public LOS_GetApplicationDocAttachment(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicationDocAttachment`, requestData);
  }
  // #endregion

  // #region LOS_GetManagerDashboardByProcess
  public LOS_GetManagerDashboardByProcess(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetManagerDashboardByProcess`, requestData);
  }
  // #endregion

  // #region LOS_GetManagerDashboardByProcess
  public LOS_GetManagerDashboardByTeam(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetManagerDashboardByTeam`, requestData);
  }
  // #endregion

  // #region LOS_SaveAssetsVehicle
  public LOS_SaveAssetsVehicle(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveAssetsVehicle`, requestData);
  }
  // #endregion

  // #region LOS_GetAssetsDetailVehicle
  public LOS_GetAssetsDetailVehicle(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetAssetsDetailVehicle`, requestData);
  }
  // #endregion

  // #region Get_Product_AssetSummary
  public Get_Product_AssetSummary(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Get_Product_AssetSummary`, requestData);
  }
  // #endregion

  // #region LOS_Get_AssetsDetail_Property
  public LOS_Get_AssetsDetail_Property(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_Get_AssetsDetail_Property`, requestData);
  }
  // #endregion

  // #region LOS_SaveAssetsProperty
  public LOS_SaveAssetsProperty(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveAssetsProperty`, requestData);
  }
  // #endregion

  // #region LOS_GetApplicatonCustomer
  public LOS_GetApplicatonCustomer(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicatonCustomer`, requestData);
  }
  // #endregion

  // #region LOS_SaveAssetsGOLD
  public LOS_SaveAssetsGOLD(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveAssetsGOLD`, requestData);
  }
  // #endregion

 // #region LOS_GetAssetsDetailGOLD
  public LOS_GetAssetsDetailGOLD(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetAssetsDetailGOLD`, requestData);
  }
  // #endregion

 // #region LOS_SaveAssetsConsumerDurable
  public LOS_SaveAssetsConsumerDurable(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveAssetsConsumerDurable`, requestData);
  }
  // #endregion

 // #region LOS_GetAssetsDetailConsumerDurable
  public LOS_GetAssetsDetailConsumerDurable(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetAssetsDetailConsumerDurable`, requestData);
  }
  // #endregion

  // #region LOS_SaveAssetsSecurity
  public LOS_SaveAssetsSecurity(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveAssetsSecurity`, requestData);
  }
  // #endregion

  // #region LOS_GetAssetsDetailSecurity
  public LOS_GetAssetsDetailSecurity(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetAssetsDetailSecurity`, requestData);
  }
  // #endregion

// #region LOS_SaveAssetsInsurance
  public LOS_SaveAssetsInsurance(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_SaveAssetsInsurance`, requestData);
  }
  // #endregion

  // #region LOS_GetAssetsDetailInsurrance
  public LOS_GetAssetsDetailInsurrance(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetAssetsDetailInsurrance`, requestData);
  }
  // #endregion

  // #region LOS_DeleteInsurance
  public LOS_DeleteInsurance(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_DeleteInsurance`, requestData);
  }
  // #endregion

  // #region LOSAssetInfoDropDown
  public LOSAssetInfoDropDown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOSAssetInfoDropDown`, requestData);
  }
  // #endregion

  // #region GET_CRC_MASTER_BY_CRCAGENCY
  public GET_CRC_MASTER_BY_CRCAGENCY(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LMS/GET_CRC_MASTER_BY_CRCAGENCY`, requestData);
  }
  // #endregion

  // #region GetCustomerByApplication
  public GetCustomerByApplication(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/GetCustomerByApplication`, requestData);
  }
  // #endregion

  // #region LOS_AssetCustomer_Info_DropDown
  public LOS_AssetCustomer_Info_DropDown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_AssetCustomer_Info_DropDown`, requestData);
  }
  // #endregion
  
  // #region LOS_GetApplicationReqDocForDropDown
  public LOS_GetApplicationReqDocForDropDown(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_GetApplicationReqDocForDropDown`, requestData);
  }
  // #endregion

  // #region GetLosChequeDetailsById
  public GetLosChequeDetailsById(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/GetLosChequeDetailsById`, requestData);
  }
  // #endregion

  // #region SaveLOSChequeDetails
  public SaveLOSChequeDetails(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/SaveLOSChequeDetails`, requestData);
  }
  // #endregion

  // #region DeleteLosChequeDetails
  public DeleteLosChequeDetails(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/DeleteLosChequeDetails`, requestData);
  }
  // #endregion

  // #region Delete_LOSReceiptDeduction
  public Delete_LOSReceiptDeduction(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Delete_LOSReceiptDeduction`, requestData);
  }
  // #endregion

  // #region LOS_ApplicationRejection_OtherProcess
  public LOS_ApplicationRejection_OtherProcess(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_ApplicationRejection_OtherProcess`, requestData);
  }
  // #endregion

  // #region LOS_Get_Pending_Inquiry_List
  public LOS_Get_Pending_Inquiry_List(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_Get_Pending_Inquiry_List`, requestData);
  }
  // #endregion
  
  // #region LOS_Master_Report_Summary
  public LOS_Master_Report_Summary(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_Master_Report_Summary`, requestData);
  }
  // #endregion
  
  // #region LOS_Master_Report_Detail
  public LOS_Master_Report_Detail(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_Master_Report_Detail`, requestData);
  }
  // #endregion
  
  // #region Update_Customer_Details_Web
  public Update_Customer_Details_Web(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Update_Customer_Details_Web`, requestData);
  }
  // #endregion Update_Customer_Details_Web

  // #region Save_CustomerInfo
  public Save_CustomerInfo(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Save_CustomerInfo`, requestData);
  }
  // #endregion Save_CustomerInfo

  // #region Get_Customer_Details_Web
  public Get_Customer_Details_Web(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Get_Customer_Details_Web`, requestData);
  }
  // #endregion Get_Customer_Details_Web

  // #region Save_Customer_Firm
  public Save_Customer_Firm(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Save_Customer_Firm`, requestData);
  }
  // #endregion Save_Customer_Firm

  // #region LOS_Application_SendBackToSales
  public LOS_Application_SendBackToSales(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_Application_SendBackToSales`, requestData);
  }
  // #endregion LOS_Application_SendBackToSales

  

  // #region LOS_DeviationCheck_On_sendToCredit
   public LOS_DeviationCheck_On_sendToCredit(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LOS_DeviationCheck_On_sendToCredit`, requestData);
  }
  // #endregion LOS_DeviationCheck_On_sendToCredit

  // #region LOS_DeviationCheck_On_sendToCredit
  public Deviation_Creation_sendToCredit(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Deviation_Creation_sendToCredit`, requestData);
  }
  // #endregion Deviation_Creation_sendToCredit

  // #region Deviation_For_ApprovalList
  public Deviation_For_ApprovalList(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Deviation_For_ApprovalList`, requestData);
  }
  // #endregion Deviation_For_ApprovalList

  // #region Deviation_Update
  public Deviation_Update(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Deviation_Update`, requestData);
  }
  // #endregion Deviation_Update

  // #region Deviation_Get_AllRemarks
  public Deviation_Get_AllRemarks(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Deviation_Get_AllRemarks`, requestData);
  }
  // #endregion Deviation_Get_AllRemarks

  // #region DeviationCheck_On_CRC
  public DeviationCheck_On_CRC(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/DeviationCheck_On_CRC`, requestData);
  }
  // #endregion DeviationCheck_On_CRC

  // #region DeviationCheck_On_PDC
  public DeviationCheck_On_PDC(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/DeviationCheck_On_PDC`, requestData);
  }
  // #endregion DeviationCheck_On_PDC
  
  


  // #region QuickLoan_Save_Fees
  public QuickLoan_Save_Fees(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/QuickLoan_Save_Fees`, requestData);
  }
  // #endregion QuickLoan_Save_Fees

  
  // #region QuickLoan_Get_Fees
  public QuickLoan_Get_Fees(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/QuickLoan_Get_Fees`, requestData);
  }
  // #endregion QuickLoan_Get_Fees
  // #region QuickLoan_Get_Fees_Details
  public QuickLoan_Get_Fees_Details(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/QuickLoan_Get_Fees_Detail`, requestData);
  }
  // #endregion QuickLoan_Get_Fees_Details
  // #start Save_Customer_FirmOutSide
  public Save_Customer_FirmOutSide(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Save_Customer_FirmOutSide `, requestData);
  }
  // #end Save_Customer_FirmOutSide
  // #start Save_Customer_FirmOutSide
  public LMS_CustomerMatch_Import(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LMS_CustomerMatch_Import `, requestData);
  }
  // #end LMS_CustomerMatch_Import


  // #start LMS_SaveCustomer_Import
  public LMS_SaveCustomer_Import(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LMS_SaveCustomer_Import `, requestData);
  }
  // #end LMS_SaveCustomer_Import
  // #start LMS_SaveCustomer_Import
  public Get_Master_CibilAcore_List() {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/Get_Master_CibilAcore_List `,null);
  }
  // #end Get_Master_CibilAcore_List

  // #start Save_Customer_FirmOutSide
  public LMS_UnaccountVoucherMatch_Import(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LMS_UnaccountVoucherMatch_Import `, requestData);
  }
  // #end LMS_UnaccountVoucherMatch_Import
  // #start LMS_Save_UnaccountVoucher_Import
  public LMS_Save_UnaccountVoucher_Import(requestData: any) {
    return this.http.post(`${constantUrl.apiEndpoint}/api/LOS/LMS_Save_UnaccountVoucher_Import `, requestData);
  }
  // #end LMS_Save_UnaccountVoucher_Import
  
}

