<div>
  <app-mat-spinner-overlay *ngIf="showSpinner"> </app-mat-spinner-overlay>
  <form
    #df="ngForm"
    novalidate
    (keydown.enter)="onSearchCRCReport()"
    class="formborder"
  >
    <div class="row m-0">
      <div class="col-md-3 p-1">
        <span class="required-lable"> New Code </span>
        <input
          name="NewCode"
          [(ngModel)]="CRCModel.NewCode"
          id="NewCode"
          placeholder="New Code"
          class="form-control input-text-css"
          required
          #refNewCode="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refNewCode.invalid,
            'alert-warning':
              refNewCode.invalid &&
              (refNewCode.dirty || refNewCode.touched || refNewCode.untouched)
          }"
        />
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable"> New Short Name </span>
        <input
          name="NewShortName"
          [(ngModel)]="CRCModel.NewShortName"
          id="NewShortName"
          placeholder="New Short Name"
          class="form-control input-text-css"
          required
          #refNewShortName="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refNewShortName.invalid,
            'alert-warning':
              refNewShortName.invalid &&
              (refNewShortName.dirty ||
                refNewShortName.touched ||
                refNewShortName.untouched)
          }"
        />
      </div>
      <div class="col-md-3 p-1">
        <span> Old Code </span>
        <input
          name="OldCode"
          [(ngModel)]="CRCModel.OldCode"
          id="OldCode"
          placeholder="Old Code"
          class="form-control input-text-css"
          #refOldCode="ngModel"
        />
      </div>
      <div class="col-md-3 p-1">
        <span> Old Short Name </span>
        <input
          name="OldShortName"
          [(ngModel)]="CRCModel.OldShortName"
          id="OldShortName"
          placeholder="Old Short Name"
          class="form-control input-text-css"
          #refOldShortName="ngModel"
        />
      </div>

      <div class="col-md-3 p-1">
        <span class="required-lable"> Close From Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="CloseFromDate"
            id="CloseFromDate"
            class="form-control input-text-css"
            [(ngModel)]="CRCModel.CloseFromDate"
            required
            #refCloseFromDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refCloseFromDate.invalid,
              'alert-warning':
                refCloseFromDate.invalid &&
                (refCloseFromDate.dirty ||
                  refCloseFromDate.touched ||
                  refCloseFromDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div>
      </div>
      <div class="col-md-3 p-1">
        <span class="required-lable"> Report Date </span>
        <div class="datepicker_feild">
          <input
            [matDatepicker]="picker2"
            dateConvert
            placeholder="DD/MM/YYYY"
            name="ReportDate"
            id="ReportDate"
            class="form-control input-text-css"
            [(ngModel)]="CRCModel.ReportDate"
            required
            #refReportDate="ngModel"
            [ngClass]="{
              'is-invalid': df.submitted && refReportDate.invalid,
              'alert-warning':
                refReportDate.invalid &&
                (refReportDate.dirty ||
                  refReportDate.touched ||
                  refReportDate.untouched)
            }"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker2"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker2></mat-datepicker>
        </div>
      </div>

      <div class="col-md-2 p-1">
        <span class="required-lable">Slab Name</span>
        <select
          name="SlabId"
          id="SlabId"
          [(ngModel)]="CRCModel.SlabId"
          class="form-control input-text-css"
          required
          #refSlabId="ngModel"
          [ngClass]="{
            'is-invalid': df.submitted && refSlabId.invalid,
            'alert-warning':
              refSlabId.invalid &&
              (refSlabId.dirty || refSlabId.touched || refSlabId.untouched)
          }"
        >
          <option value="">Select Slab Name</option>
          <option *ngFor="let row of SlabData" [value]="row.Id">
            {{ row.Slab_name }}
          </option>
        </select>
      </div>
      <div class="col-md-1 p-1 text-right">
        <button
          type="button"
          (click)="onSearchCRCReport()"
          class="mt-3 btn font-size-12 button-btn"
          [disabled]="!df.form.valid"
        >
          Search
        </button>
      </div>
      <div
        class="col-md-2 mt-3"
        [hidden]="!DataSource"
        style="margin-left: 25px"
      >
        <form action="" class="search-text">
          <div class="bg-light rounded rounded-pill shadow-sm">
            <div class="input-group">
              <input
                type="text"
                id="FilterInputSearch"
                (keyup)="applyFilter($event.target.value)"
                name="FilterInputSearch"
                placeholder="What're you searching for?"
                class="form-control border-0 bg-light"
                style="text-align: right"
              />
              <div class="input-group-append">
                <button
                  id="button-addon1"
                  type="submit"
                  class="btn btn-link text-primary"
                >
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </form>

  <div class="mt-3" [hidden]="!DataSource">
    <div class="">
      <div
        class="table-responsive"
        style="height: 280px; max-width: 100%; overflow: auto"
      >
        <table
          mat-table
          class="table-bordered"
          [dataSource]="DataSource"
          matSort
          matTableExporter
          #exporter="matTableExporter"
          style="max-width: 100%; overflow: auto"
        >
          <ng-container matColumnDef="Id">
            <th
              mat-header-cell
              mat-sort-header
              style="max-width: 50px"
              *matHeaderCellDef
            >
              #
            </th>
            <td
              mat-cell
              *matCellDef="let row; let i = index"
              class="grid-cell mw50"
            >
              {{ paginatorRef.pageIndex * paginatorRef.pageSize + (i + 1) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Customer_Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Customer Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Customer_Name }}</td>
          </ng-container>

          <ng-container matColumnDef="Customer_DOB">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Customer DOB
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Customer_DOB }}</td>
          </ng-container>

          <ng-container matColumnDef="Gender">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Gender</th>
            <td mat-cell *matCellDef="let row">
              {{ row.Gender === 1 ? "Female" : "Male" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Income_Tax_Number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>PAN No</th>
            <td mat-cell *matCellDef="let row">{{ row.Income_Tax_Number }}</td>
          </ng-container>

          <ng-container matColumnDef="Passport_Number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Passport No
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Passport_Number }}</td>
          </ng-container>

          <ng-container matColumnDef="Passport_IssueDate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Passport Issue Date
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Passport_IssueDate }}</td>
          </ng-container>

          <ng-container matColumnDef="Passport_ExpiredDate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Passport Expiry Date
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Passport_ExpiredDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Voter_Id_Number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Votter ID</th>
            <td mat-cell *matCellDef="let row">{{ row.Voter_Id_Number }}</td>
          </ng-container>

          <ng-container matColumnDef="Driving_License_Number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Driving License Number
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Driving_License_Number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Driving_License_IssueDate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Driving Licence Issue Date
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Driving_License_IssueDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Driving_License_ExpiredDate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Driving Licence Expiry Date
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Driving_License_ExpiredDate }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Ration_Card_Number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Ration Card Number
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Ration_Card_Number }}</td>
          </ng-container>

          <ng-container matColumnDef="Universal_Id_Number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Universal Id No
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Universal_Id_Number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Additional_Id1_Number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Additional Id 1
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Additional_Id1_Number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Additional_Id2_Number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Additional Id 2
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Additional_Id2_Number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Telephone_No_Mobile">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Mobile No</th>
            <td mat-cell *matCellDef="let row">
              {{ row.Telephone_No_Mobile }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Telephone_No_Residence">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Phone Residence
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Telephone_No_Residence }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Telephone_No_Office">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Phone Office
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Telephone_No_Office }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Extention_Office">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Extension Office
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Extention_Office }}</td>
          </ng-container>

          <ng-container matColumnDef="Telephone_No_Other">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Phone Other
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Telephone_No_Other }}</td>
          </ng-container>

          <ng-container matColumnDef="Email_Id1">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Email Id 1
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Email_Id1 }}</td>
          </ng-container>

          <ng-container matColumnDef="Email_Id2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Email Id 2
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Email_Id2 }}</td>
          </ng-container>

          <ng-container matColumnDef="Address1">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Address 1</th>
            <td mat-cell *matCellDef="let row">{{ row.Address1 }}</td>
          </ng-container>

          <ng-container matColumnDef="State_Code1">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              State Code 1
            </th>
            <td mat-cell *matCellDef="let row">{{ row.State_Code1 }}</td>
          </ng-container>

          <ng-container matColumnDef="Pincode1">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Pincode 1</th>
            <td mat-cell *matCellDef="let row">{{ row.Pincode1 }}</td>
          </ng-container>

          <ng-container matColumnDef="Address_Category1">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Address Category 1
            </th>
            <td mat-cell *matCellDef="let row">
              <span [ngSwitch]="row.Address_Category1">
                <span *ngSwitchCase="01">Permanent Address</span>
                <span *ngSwitchCase="02">Residence Address</span>
                <span *ngSwitchCase="03">Office Address</span>
                <span *ngSwitchDefault>Not Categorized</span>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Residence_Code1">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Residence Code 1
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Residence_Code1 === "01" ? "Owned" : "Rented" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Address2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Address 2</th>
            <td mat-cell *matCellDef="let row">{{ row.Address2 }}</td>
          </ng-container>

          <ng-container matColumnDef="State_Code2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              State Code 2
            </th>
            <td mat-cell *matCellDef="let row">{{ row.State_Code2 }}</td>
          </ng-container>

          <ng-container matColumnDef="Pincode2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Pincode 2</th>
            <td mat-cell *matCellDef="let row">{{ row.Pincode2 }}</td>
          </ng-container>

          <ng-container matColumnDef="Address_Category2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Address Category 2
            </th>
            <td mat-cell *matCellDef="let row">
              <span [ngSwitch]="row.Address_Category2">
                <span *ngSwitchCase="01">Permanent Address</span>
                <span *ngSwitchCase="02">Residence Address</span>
                <span *ngSwitchCase="03">Office Address</span>
                <span *ngSwitchDefault>Not Categorized</span>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Residence_Code2">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Residence Code 2
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Residence_Code2 === "01" ? "Owned" : "Rented" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="New_Member_Code">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              CNew Member Code
            </th>
            <td mat-cell *matCellDef="let row">{{ row.New_Member_Code }}</td>
          </ng-container>

          <ng-container matColumnDef="New_Member_Short_Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              New Member Short Name
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.New_Member_Short_Name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="New_Account_Number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              New Account No
            </th>
            <td mat-cell *matCellDef="let row">{{ row.New_Account_Number }}</td>
          </ng-container>

          <ng-container matColumnDef="New_Account_Type">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              New Account Type
            </th>
            <td mat-cell *matCellDef="let row">{{ row.New_Account_Type }}</td>
          </ng-container>

          <ng-container matColumnDef="New_Ownerhip_Indicator">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              New Ownership Indicator
            </th>
            <td mat-cell *matCellDef="let row">
              <span [ngSwitch]="row.New_Ownerhip_Indicator">
                <span *ngSwitchCase="01">Borrower</span>
                <span *ngSwitchCase="03">Guarantor</span>
                <span *ngSwitchCase="04">Coborrower</span>
                <span *ngSwitchDefault>Joint</span>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Date_Opened">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Date Opened
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Date_Opened }}</td>
          </ng-container>

          <ng-container matColumnDef="Date_Of_Last_Payment">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Date of Last Payment
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Date_Of_Last_Payment }}
            </td>
          </ng-container>
          <ng-container matColumnDef="Date_Closed">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Date Closed
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Date_Closed }}</td>
          </ng-container>

          <ng-container matColumnDef="DateReported">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Date Reported
            </th>
            <td mat-cell *matCellDef="let row">{{ row.DateReported }}</td>
          </ng-container>

          <ng-container matColumnDef="High_Credit">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              High Credit
            </th>
            <td mat-cell *matCellDef="let row">{{ row.High_Credit }}</td>
          </ng-container>

          <ng-container matColumnDef="Current_Balance">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Current Balance
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Current_Balance }}</td>
          </ng-container>

          <ng-container matColumnDef="Amount_Overdue">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Amt Overdue
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Amount_Overdue }}</td>
          </ng-container>

          <ng-container matColumnDef="DPD_Days">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>DPD Days</th>
            <td mat-cell *matCellDef="let row">{{ row.DPD_Days }}</td>
          </ng-container>

          <ng-container matColumnDef="OldCode">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Old Code</th>
            <td mat-cell *matCellDef="let row">{{ row.OldCode }}</td>
          </ng-container>

          <ng-container matColumnDef="OldShort_Name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Old Short Name
            </th>
            <td mat-cell *matCellDef="let row">{{ row.OldShort_Name }}</td>
          </ng-container>

          <ng-container matColumnDef="Old_Account_Number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Old Acc No
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Old_Account_Number }}</td>
          </ng-container>

          <ng-container matColumnDef="Old_Account_Type">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Old Acc Type
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Old_Account_Type }}</td>
          </ng-container>

          <ng-container matColumnDef="Old_Ownerhip_Indicator">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Old Ownership Indicator
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Old_Ownerhip_Indicator }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Suit_Filed">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Suit Filed
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Suit_Filed }}
            </td>
          </ng-container>

          <ng-container matColumnDef="WriteOff_Settled_Status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Written-off Settled Status
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.WriteOff_Settled_Status }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Assets_Classification">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Asset Classification
            </th>
            <td mat-cell *matCellDef="let row">
              <span [ngSwitch]="row.Assets_Classification">
                <span *ngSwitchCase="01">Standard</span>
                <span *ngSwitchCase="02">Substandard</span>
                <span *ngSwitchCase="03">Doubtful</span>
                <span *ngSwitchCase="04">Loss</span>
                <span *ngSwitchDefault>Special Mention Account</span>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="value_Of_collateral">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Value of Collateral
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.value_Of_collateral }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Collateral_Type">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Collateral Type
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Collateral_Type }}</td>
          </ng-container>

          <ng-container matColumnDef="Credit_Limit">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Credit Limit
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Credit_Limit }}</td>
          </ng-container>

          <ng-container matColumnDef="Cash_Limit">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Cash Limit
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Cash_Limit }}</td>
          </ng-container>

          <ng-container matColumnDef="RateOf_Interest">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Rate of Interest
            </th>
            <td mat-cell *matCellDef="let row">{{ row.RateOf_Interest }}</td>
          </ng-container>

          <ng-container matColumnDef="Repayment_Tenure">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Repayment Tenure
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Repayment_Tenure }}</td>
          </ng-container>

          <ng-container matColumnDef="EMI_Amount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              EMI Amount
            </th>
            <td mat-cell *matCellDef="let row">{{ row.EMI_Amount }}</td>
          </ng-container>

          <ng-container matColumnDef="Writen_Of_Amount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Written off Amount
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Writen_Of_Amount }}</td>
          </ng-container>

          <ng-container matColumnDef="Writen_Of_Principle">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Written of Principal Amount
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Writen_Of_Principle }}
            </td>
          </ng-container>

          

          <ng-container matColumnDef="Settlement_Amount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Settlement Amt
            </th>
            <td mat-cell *matCellDef="let row">{{ row.Settlement_Amount }}</td>
          </ng-container>

          <ng-container matColumnDef="Payment_Frequency">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Payment Frequency
            </th>
            <td mat-cell *matCellDef="let row">
              <span [ngSwitch]="row.Payment_Frequency">
                <span *ngSwitchCase="03">Monthly</span>
                <span *ngSwitchCase="04">Quarterly</span>
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="Actual_Payment_Amount">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Actual Payment Amount
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Actual_Payment_Amount }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Customer_Occupation">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Customer Occupation
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Customer_Occupation }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Income">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Income</th>
            <td mat-cell *matCellDef="let row">{{ row.Income }}</td>
          </ng-container>

          <ng-container matColumnDef="Net_Gross_Income_Indicator">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Net Gross Income Indicator
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Net_Gross_Income_Indicator }}
            </td>
          </ng-container>

          <ng-container matColumnDef="Monthly_Annual_Income_Indicator">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Monthly/Annual Income Indicator
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Monthly_Annual_Income_Indicator }}
            </td>
          </ng-container>



          <ng-container matColumnDef="Nrega_Card_Number">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Nrega Card Number
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.Nrega_Card_Number }}
            </td>
          </ng-container>

          <ng-container matColumnDef="CKYC">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              C-KYC
            </th>
            <td mat-cell *matCellDef="let row">
              {{ row.CKYC }}
            </td>
          </ng-container>




          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            (click)="highlightRow(row)"
            [class.highlight]="i == selectedId"
            *matRowDef="let row; let i = index; columns: displayedColumns"
          ></tr>
        </table>
      </div>
    </div>
  </div>
  <div class="row m-0 ml-1 col-md-12" [hidden]="!DataSource">
    <div class="col-md-2 pt-2">
      <button class="btn font-size-12 button-btn" (click)="exportExcel()">
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Export
      </button>
    </div>
    <div class="col-md-2 pt-2">
      <button
        class="btn font-size-12 button-btn"
        style="width: max-content !important"
        (click)="dowloadTxtFile()"
      >
        <i
          class="fa fa-file-export mr-1"
          style="font-size: medium; cursor: pointer"
        ></i>
        Dowload TUDF File
      </button>
    </div>
    <div class="col-md-8">
      <mat-paginator
        #paginatorRef
        [pageSizeOptions]="[20, 50]"
        showFirstLastButtons
      >
      </mat-paginator>
    </div>
  </div>
</div>
