import {
  Component,
  OnInit,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { DataSharingService } from "../../AuthGuard/DataSharingService";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { LosService } from "../../_LOS/services/los.service";
import { MasterService } from "../../Shared/app.Masters.Service";
declare var $: any;
import { SnackbarComponent } from "../../snackbar/snackbar.component";
import { constantUrl } from "../../Shared/constantUrl";
import { EncrDecrService } from "../../AuthGuard/EncrDecrService";
import { LmsService } from "../services/lms.service";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: "app-lms-crc-fill",
  templateUrl: "./lms-crc-fill.component.html",
  styleUrls: ["./lms-crc-fill.component.scss"],
})
export class LmsCrcFillComponent implements OnInit {
  currentUser: any;
  isShowSave: boolean = true;
  isShowEdit: boolean = false;
  showSpinner: boolean = false;
  DetailCRCShow: boolean = false;
  isShowUpdate: boolean = false;
  CibilApiShow: boolean = false;
  CibilApidecisioncentreShow: boolean = false;
  configSuccess: MatSnackBarConfig = {
    panelClass: "style-success",
    duration: 5000,
    horizontalPosition: "right",
    verticalPosition: "top",
  };
  AddButtonShow: boolean = true;
  crcModel: any = {};
  CRC_Type: any;
  SelectCRCType: any;
  ApplicationDetail: any;
  LoanId: any;
  DocModel: any = {};
  DocModel1: any = {};
  loading: boolean = false;
  FormCRCShow: boolean = true;
  CurrentDate: any = new Date();

  docBaseUrl: any = constantUrl.CrcApplicationDocUrl;
  customerProfileBaseUrl: any = constantUrl.customerProfilePicUrl;
  AllowExtension: any = constantUrl.AllowExtension;
  datasourceCRC: any;
  displayedCRCColumns: any = [
    "CRC_AgencyName",
    "CRC_LoanTypeName",
    "CRC_CreditVisionScore",
    "CRC_PersonalLoanScore",
    "CRC_Remarks",
    "Action",
  ];

  kycList: any[] = [];
  CRC_LoanTypeDropDown: any[] = [];
  CRC_Agency: any;
  today: Date = new Date();
  inViewMode: boolean = false;
  Type: any;
  @Input("Data") Data: any;
  @Input("isEdit") isEdit: boolean = false;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  //Type: any;
  @Input() loanSummary: any;
  @Input() CustomerId: any;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  CRCList: any = [];
  PageAcess: any;
  CRC_Agency_Bind: any;
  Response: any;
  cibilResponse: any;
  error: any = true
  dataUrl:any;
  constructor(
    private _MasterService: MasterService,
    private LmsService: LmsService,
    private dataSharingService: DataSharingService,
    private encdec: EncrDecrService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router,
    private losService: LosService
  ) {
    this.route.paramMap.subscribe((param: ParamMap) => {
      //this.LoanId = param.get("id");// encdec.decrypt(param.get("id"));
      // this.CustomerId = param.get("cid");// encdec.decrypt(param.get("cid"));
      //this.LoanId = param.get("LA-No");
    });
  }
  ngOnInit(): void {
    this.CustomerId = this.CustomerId;
    this.LoanId = this.loanSummary.LoanId;
    this.getAgency();
    //this.dataSharingService.LOSHeaderTitle.next('CRC Fill');
    this.dataSharingService.LOSHeaderTitle.next("CRC Fill");
    this.currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
    this.GetLmsCRCCustomerDetail();
  }
  GetLmsCRCCustomerDetail() {
    // this.Type = Type;
    this.showSpinner = true;

    this.LmsService.GetLmsCRCCustomerDetail({
      Loan_Id: this.loanSummary.LoanId,
      CId: this.CustomerId,
    }).subscribe((res: any) => {
      this.showSpinner = false;
      this.ApplicationDetail = res.Item1[0];

      //console.log("Res : ", res);
      this.kycList = res.Item2;
      var inViewMode =
        this.ApplicationDetail.CRC_Status == "Completed" ||
        this.ApplicationDetail.CRC_Status == "Rejected";
      this.crcModel = res.Item3[0] || {
        CRC_Agency: this.ApplicationDetail.DefaultCRCAgency || "",
        CRC_Recommendation: "",
      };
      this.datasourceCRC = new MatTableDataSource(res.Item3);
      this.CRCList = res.Item3;
      if (this.ApplicationDetail.TransUnionCibilAPI && this.ApplicationDetail.IsFirm!=1) {
        var _find = this.CRCList.find((obj: any) => {
          return obj.CRC_Agency == 1;
        });
        if (!_find) {
          var _find1 = this.CRCList.find((obj: any) => {
            return obj.CRC_Agency == this.ApplicationDetail.DefaultCRCAgency;
          });

          if (!_find1) {
            if (
              this.ApplicationDetail.DefaultCRCAgency == 1 ||
              this.ApplicationDetail.DefaultCRCAgency == "1"
            ) {
              this.CibilApiShow = true;
              this.AddButtonShow = false;
            } else {
              this.CibilApiShow = false;
              this.AddButtonShow = true;
            }
          } else {
            this.CibilApiShow = true;
            this.AddButtonShow = true;
          }
        } else {
          this.CibilApiShow = false;
          this.AddButtonShow = true;
        }
      }
      if (this.ApplicationDetail.Transuniondecisioncentre  && this.ApplicationDetail.IsFirm!=1) {

        var _find = this.CRCList.find((obj: any) => {
          return obj.CRC_Agency == 1;
        });
        if (!_find) {
          var _find1 = this.CRCList.find((obj: any) => {
            return obj.CRC_Agency == this.ApplicationDetail.DefaultCRCAgency;
          });

          if (!_find1) {
            if (
              this.ApplicationDetail.DefaultCRCAgency == 1 ||
              this.ApplicationDetail.DefaultCRCAgency == "1"
            ) {
              this.CibilApidecisioncentreShow = true;
              this.AddButtonShow = false;
            } else {
              this.CibilApidecisioncentreShow = false;
              this.AddButtonShow = true;
            }
          } else {
            this.CibilApidecisioncentreShow = true;
            this.AddButtonShow = true;
          }
        } else {
          this.CibilApidecisioncentreShow = false;
          this.AddButtonShow = true;
        }
      }
      //console.log("this.crcModel", this.crcModel);
      if (res.Item3[0] != undefined) {
        if (
          this.crcModel.CRC_OldestAccountOpenDate != null &&
          this.crcModel.CRC_OldestAccountOpenDate != ""
        )
          this.crcModel.CRC_OldestAccountOpenDate = new Date(
            this.crcModel.CRC_OldestAccountOpenDate
          );
        if (
          this.crcModel.CRC_RecentAccountOpenDate != null &&
          this.crcModel.CRC_RecentAccountOpenDate != ""
        )
          this.crcModel.CRC_RecentAccountOpenDate = new Date(
            this.crcModel.CRC_RecentAccountOpenDate
          );
        if (
          this.crcModel.CRC_LastEnquiryDate != null &&
          this.crcModel.CRC_LastEnquiryDate != ""
        )
          this.crcModel.CRC_LastEnquiryDate = new Date(
            this.crcModel.CRC_LastEnquiryDate
          );
      }
      if (this.crcModel.CRC_Agency) {
        this.GET_CRC_LoanType(false);
      }
      this.getCRCType(this.crcModel.CRC_Agency);

      this.crcModel.CRC_CreateBy = this.currentUser.userId;
      //console.log("inViewMode ", inViewMode);
      if (inViewMode == true) {
        this.DetailCRCShow = true;
        this.FormCRCShow = false;
        this.isShowEdit = true;
        this.isShowSave = false;
        this.isShowUpdate = false;
      } else {
        this.DetailCRCShow = false;
        this.FormCRCShow = true;
        this.getCRCType(this.crcModel.CRC_Agency);
        this.isShowEdit = false;
        this.isShowSave = true;
        this.isShowUpdate = false;
      }

      this._MasterService.Get_CibilAPI_Request_Response({ Loan_Id: this.LoanId, CId: this.CustomerId })
        .subscribe((res: any) => {
          if (res.length > 0) {
            this.CibilApiShow = false;
            this.CibilApidecisioncentreShow = false;
            this.AddButtonShow = true;
          }

        })
    });
  }
  getCRCType(event: any) {
    this.LmsService.GET_CRC_MASTER_BY_CRCAGENCY({
      Crc_Agency: this.crcModel.CRC_Agency,
    }).subscribe((res: any) => {
      this.CRC_Type = res;
    });
  }
  getAgency() {
    this._MasterService
      .Commaon_Master({ Commands: "Select", Type: "CRC AGENCY" })
      .subscribe((res: any) => {
        this.CRC_Agency_Bind = res;
      });
  }
  fileChangeListener(files: any) {
    // debugger;
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB && Ext != "pdf") {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      this.crcModel.CRC_ReportName = "";
      this.DocModel.DocName = "";
      this.DocModel.DocData = "";
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      this.crcModel.CRC_ReportName = "";
      this.DocModel.DocName = "";
      this.DocModel.DocData = "";
      return;
    }

    let $this = this;
    let reader = new FileReader();
    let _pre = this.today.getTime();
    this.crcModel.CRC_ReportName = _pre + "_" + files[0].name;
    this.DocModel.DocName = _pre + "_" + files[0].name;
    this.DocModel.ApplicationNo = this.loanSummary.ApplicationNo;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.DocModel.DocData = reader.result.toString().split(";base64,").pop();
      return;
    };
  }
  removeFile(input: any) {
    input.value = "";
    this.crcModel.CRC_ReportName = "";
    this.DocModel.DocName = "";
    this.DocModel.DocData = "";
  }
  fileChangeListener1(files: any) {
    let Ext = files[0].name.split(".").pop().toLowerCase();
    var FileSize = Math.floor(files[0].size / Math.pow(1024, 1));

    if (FileSize > this.currentUser.UploadMaxSize_In_KB && Ext != "pdf") {
      var Msg = `Upload file size should be less than or equal to ${this.currentUser.UploadMaxSize_In_KB} KB`;
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: Msg,
        ...this.configSuccess,
      });
      files.value = "";
      this.crcModel.CRC_ReportName1 = "";
      this.DocModel1.DocName = "";
      this.DocModel1.DocData = "";
      return;
    }

    if (!this.AllowExtension.includes(Ext)) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: `Invalid file type`,
        ...this.configSuccess,
      });
      files.value = "";
      this.crcModel.CRC_ReportName1 = "";
      this.DocModel1.DocName = "";
      this.DocModel1.DocData = "";
      return;
    }

    let $this = this;
    let reader = new FileReader();
    if (
      this.crcModel.CRC_ReportName &&
      this.crcModel.CRC_ReportName.split("_")[1] == files[0].name
    ) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Same File can not be upload.",
        ...this.configSuccess,
      });
      return;
    }
    let _pre = this.today.getTime();
    this.crcModel.CRC_ReportName1 = _pre + "_" + files[0].name;
    this.DocModel1.DocName = _pre + "_" + files[0].name;
    this.DocModel1.ApplicationNo = this.loanSummary.ApplicationNo;
    reader.readAsDataURL(files[0]);
    reader.onload = function () {
      $this.DocModel1.DocData = reader.result
        .toString()
        .split(";base64,")
        .pop();
      return;
    };
  }
  removeFile1(input: any) {
    input.value = "";
    this.crcModel.CRC_ReportName1 = "";
    this.DocModel1.DocName = "";
    this.DocModel1.DocData = "";
  }
  onSaveCrc() {
    if (!this.DocModel.DocData) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please upload CRC report.",
        ...this.configSuccess,
      });
    } else {
      this.UploadCrcDoc();
    }
  }

  UpdateCrc() {
    if (this.DocModel.DocData) {
      this.losService.UploadCrcDoc(this.DocModel).subscribe((res: any) => {
        if (this.DocModel1.DocData) {
          this.UpdateCrcDoc1();
        } else {
          this.UpdateCrcReport();
        }
      });
    } else {
      //this.UploadCrcDoc();
      if (!this.crcModel.CRC_ReportName) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "Please upload CRC report.",
          ...this.configSuccess,
        });
      } else {
        this.UpdateCrcReport();
      }
    }
  }

  UpdateCrc1() {
    //console.log("update this.crcModel", this.crcModel);
    if (!this.DocModel.DocData) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please upload CRC report.",
        ...this.configSuccess,
      });
    } else {
      //this.UploadCrcDoc();
      this.showSpinner = true;

      this.losService.UploadCrcDoc(this.DocModel).subscribe(
        (res: any) => {
          if (this.DocModel1.DocData) {
            this.UpdateCrcDoc1();
          } else {
            // this.saveCrcReport();
            this.losService.UploadCrcDoc(this.DocModel1).subscribe(
              (res: any) => {
                this.UpdateCrcReport();
              },
              (err: any) => {
                this.snackBar.openFromComponent(SnackbarComponent, {
                  data: "File not uploaded.",
                  ...this.configSuccess,
                });
              }
            );
          }
        },
        (err: any) => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: "File not uploaded.",
            ...this.configSuccess,
          });
        }
      );
    }
  }
  UpdateCrcReport() {
    this.loading = true;
    this.crcModel.CRC_ApplicationId = 0;
    this.crcModel.LoanId = this.loanSummary.LoanId;
    this.crcModel.CRC_CustomerId = this.CustomerId;
    if (
      this.crcModel.CRC_CreditVisionScore == null ||
      this.crcModel.CRC_CreditVisionScore == 0
    )
      this.crcModel.CRC_CreditVisionScore = "0";
    if (
      this.crcModel.CRC_PersonalLoanScore == null ||
      this.crcModel.CRC_PersonalLoanScore == 0
    )
      this.crcModel.CRC_PersonalLoanScore = "0";
    // console.log("this.Update", this.crcModel);
    this.LmsService.LmsUpdateCrc({
      JSON: JSON.stringify({
        CRC: this.dataSharingService.cleanObject(this.crcModel),
      }),
    }).subscribe((res: any) => {
      this.loading = false;
      this.showSpinner = false;
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
        //this.QuickLoan_Save_PageProcess('CRC');
        this.OnClose(true);
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
  }
  saveCrcReport() {
    this.loading = true;
    //console.log("this.crcModel", this.crcModel);
    this.crcModel.LoanId = this.loanSummary.LoanId;
    this.crcModel.CRC_CustomerId = this.CustomerId;
    this.crcModel.CRC_ApplicationId = 0;
    this.crcModel.CRC_CreateBy = this.currentUser.userId;
    if (
      this.crcModel.CRC_CreditVisionScore == null ||
      this.crcModel.CRC_CreditVisionScore == 0
    )
      this.crcModel.CRC_CreditVisionScore = "0";
    if (
      this.crcModel.CRC_PersonalLoanScore == null ||
      this.crcModel.CRC_PersonalLoanScore == 0
    )
      this.crcModel.CRC_PersonalLoanScore = "0";
    this.LmsService.LmsSaveCrc({
      JSON: JSON.stringify({
        CRC: this.dataSharingService.cleanObject(this.crcModel),
      }),
    }).subscribe((res: any) => {
      this.loading = false;
      this.showSpinner = false;
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
        //this.QuickLoan_Save_PageProcess('CRC');
        this.OnClose(true);
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
  }
  UploadCrcDoc() {
    this.showSpinner = true;
    this.losService.UploadCrcDoc(this.DocModel).subscribe(
      (res: any) => {
        if (this.DocModel1.DocData) {
          this.UploadCrcDoc1();
        } else {
          this.saveCrcReport();
        }
      },
      (err: any) => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "File not uploaded.",
          ...this.configSuccess,
        });
      }
    );
  }
  UploadCrcDoc1() {
    this.losService.UploadCrcDoc(this.DocModel1).subscribe(
      (res: any) => {
        this.saveCrcReport();
      },
      (err: any) => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "File not uploaded.",
          ...this.configSuccess,
        });
      }
    );
  }
  UpdateCrcDoc1() {
    this.losService.UploadCrcDoc(this.DocModel1).subscribe(
      (res: any) => {
        this.UpdateCrcReport();
      },
      (err: any) => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: "File not uploaded.",
          ...this.configSuccess,
        });
      }
    );
  }

  OnClose(isRefresh: boolean = false) {
    // setTimeout(() => {
    //   this.dataSharingService.LmsHeaderLoanNo.next(this.LoanNo);
    // }, 2);

    //this.router.navigate([
    //  `/lms/lms-crc-list`,
    //]);
    // let element: HTMLElement = document.getElementById("CrcInfo") as HTMLElement;
    // element.click();
    this.close.emit(isRefresh);
    //this.action.emit(isRefresh);
  }
  EditCRC() {
    this.isShowEdit = false;
    this.isShowSave = false;
    this.inViewMode = false;
    this.DetailCRCShow = false;
    this.FormCRCShow = true;
    this.isShowUpdate = true;
  }

  //QuickLoan_Save_PageProcess(PageType) {
  //  this._QuickLoanService.QuickLoan_Save_PageProcess({
  //    PageType: PageType, ProductId: this.loanSummary.ProductId, Loan_Id: this.loanSummary.LoanId,
  //    Process_Id: this.Processid, Page_Id: this.PageData.MM_Id, LoginUserId: this.currentUser.userId
  //  }).subscribe((res) => {
  //    //return res[0].CODE;
  //  });
  //}
  getCRCDetail(data: any, isEdit: boolean) {
    this.crcModel = data;
    this.GET_CRC_LoanType(false);
    this.isShowEdit = true;
    this.isShowSave = false;
    this.isShowUpdate = false;
    this.inViewMode = true;
    if (
      this.crcModel.CRC_OldestAccountOpenDate != null &&
      this.crcModel.CRC_OldestAccountOpenDate != "" &&
      this.crcModel.CRC_OldestAccountOpenDate != "0001-01-01T00:00:00"
    )
      this.crcModel.CRC_OldestAccountOpenDate = new Date(
        this.crcModel.CRC_OldestAccountOpenDate
      );
    else {
      this.crcModel.CRC_OldestAccountOpenDate = null;
    }
    if (
      this.crcModel.CRC_RecentAccountOpenDate != null &&
      this.crcModel.CRC_RecentAccountOpenDate != "" &&
      this.crcModel.CRC_RecentAccountOpenDate != "0001-01-01T00:00:00"
    )
      this.crcModel.CRC_RecentAccountOpenDate = new Date(
        this.crcModel.CRC_RecentAccountOpenDate
      );
    else this.crcModel.CRC_RecentAccountOpenDate = null;
    if (
      this.crcModel.CRC_LastEnquiryDate != null &&
      this.crcModel.CRC_LastEnquiryDate != "" &&
      this.crcModel.CRC_LastEnquiryDate != "0001-01-01T00:00:00"
    )
      this.crcModel.CRC_LastEnquiryDate = new Date(
        this.crcModel.CRC_LastEnquiryDate
      );
    else this.crcModel.CRC_LastEnquiryDate = null;

    this.crcModel.CRC_ApplicationId = this.LoanId;
    this.crcModel.CRC_CustomerId = this.CustomerId;
    this.crcModel.CRC_CreateBy = this.currentUser.userId;
    //  this.crcModel.CRC_OldestAccountOpenDate = new Date(this.crcModel.CRC_OldestAccountOpenDate);
    //   this.crcModel.CRC_RecentAccountOpenDate = new Date(this.crcModel.CRC_RecentAccountOpenDate);
    //   this.crcModel.CRC_LastEnquiryDate = new Date(this.crcModel.CRC_LastEnquiryDate);

    $("#AdditionalCRC").modal("show");
    $("#AdditionalCRC").css("z-index", "1050");
  }
  RemoveElementFromArray(element: number) {
    this.CRC_Agency_Bind.forEach((value, index) => {
      if (value.Id == element) this.CRC_Agency_Bind.splice(index, 1);
    });
  }
  GET_CRC_LoanType(isChange: boolean = true) {
    if (isChange) {
      this.crcModel.CRC_LoanType = "";
    }
    this.losService
      .GET_CRC_MASTER_BY_CRCAGENCY({
        Crc_Agency: this.crcModel.CRC_Agency,
        Is_Firm: this.ApplicationDetail.IsFirm,
      })
      .subscribe((res: any) => {
        this.CRC_LoanTypeDropDown = res;
      });
  }
  AddCRC() {
    if (this.CRCList.length == 0) {
      this.crcModel.CRC_Agency = this.ApplicationDetail.deDefaultCRCAgency;
      this.GET_CRC_LoanType();
      this.crcModel = {
        CRC_Agency: this.ApplicationDetail.DefaultCRCAgency || "",
        CRC_LoanType: "",
        CRC_Recommendation: "",
      };
    } else {
      this.crcModel = {
        CRC_Agency: "",
        CRC_LoanType: "",
        CRC_Recommendation: "",
      };
    }

    if (this.CibilApiShow || this.CibilApidecisioncentreShow) {
      this.RemoveElementFromArray(1);
    }
    this.CRCList.forEach((obj1) => {
      this.RemoveElementFromArray(Number(obj1.CRC_Agency));
      //if (Number(obj1.CRC_Agency) === Number(obj.Id)) {

      //  this.CRC_Agency_Bind.splice(index, 1);
      //}
    });

    // });

    //this.CRC_Agency_Bind = this.CRC_Agency_Bind.filter(x => x.Id == "Cheque & Repayment")
    this.isShowEdit = false;
    this.isShowSave = true;
    this.isShowUpdate = false;
    this.inViewMode = false;

    console.log(" this.crcModel ", this.crcModel);
    $("#AdditionalCRC").modal("show");
    $("#AdditionalCRC").css("z-index", "1050");
  }
  Delete_CRC(data: any) {
    this.loading = true;
    this.crcModel = data;
    this.crcModel.CRC_ApplicationId = 0;
    this.crcModel.LoanId = this.LoanId;
    this.crcModel.CRC_CustomerId = this.CustomerId;
    if (
      this.crcModel.CRC_CreditVisionScore == null ||
      this.crcModel.CRC_CreditVisionScore == 0
    )
      this.crcModel.CRC_CreditVisionScore = "0";
    if (
      this.crcModel.CRC_PersonalLoanScore == null ||
      this.crcModel.CRC_PersonalLoanScore == 0
    )
      this.crcModel.CRC_PersonalLoanScore = "0";
    // console.log("this.Update", this.crcModel);
    this.crcModel.Mode = "Delete";
    this.LmsService.LmsUpdateCrc({
      JSON: JSON.stringify({
        CRC: this.dataSharingService.cleanObject(this.crcModel),
      }),
    }).subscribe((res: any) => {
      this.loading = false;
      this.showSpinner = false;
      if (res[0].CODE == 0) {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
        this.OnClose();
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: res[0].MSG,
          ...this.configSuccess,
        });
      }
    });
    this.OnAdditionalCRCClose();
  }
  OnAdditionalCRCClose() {
    this.getAgency();
    $("#AdditionalCRC").modal("hide");
  }
  CheckCibil() {
    this.CibilApiShow = false;
    // console.log(
    //   "(this.ApplicationDetail.LoanAmount",
    //   this.ApplicationDetail.LoanAmount
    // );
    if (this.ApplicationDetail.LoanAmount == undefined || this.ApplicationDetail.LoanAmount == null || this.ApplicationDetail.LoanAmount == 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please fill Account Information!!",
        ...this.configSuccess,
      });
    } else {
      let LoanAmount: string =
        "0".repeat(9 - this.ApplicationDetail.LoanAmount.toString().length) +
        this.ApplicationDetail.LoanAmount.toString();

      var ids = [];
      this.kycList.forEach((value, index) => {
        if (value.Doc_Type_Cibil != "" && value.Doc_Type_Cibil != null) {
          ids.push({
            index: "I0" + (index + 1).toString(),
            idNumber: value.KYC_DocNumber,
            idType: value.Doc_Type_Cibil,
          });
        }
      });
      var replacementArray = ['!', '#', '$', '%', '&', '*', ',', '/', '?', '@', '\/', ' ^ ', ' | ', '~', ' + ', '='];
      for (let i = 0; i < replacementArray.length; i++) {
        this.ApplicationDetail.Customer_FirstName = this.ApplicationDetail.Customer_FirstName.replace(replacementArray[i], '');
        this.ApplicationDetail.Customer_LastName = this.ApplicationDetail.Customer_LastName.replace(replacementArray[i], '');
        this.ApplicationDetail.Line1 = this.ApplicationDetail.Line1.replace(replacementArray[i], '');
        this.ApplicationDetail.Line2 = this.ApplicationDetail.Line2.replace(replacementArray[i], '');
        ids.forEach((value, index) => {
          value.idNumber = value.idNumber.replace(replacementArray[i], '');
        })
      }
      var chunks = this.splitStringIntoChunks(this.ApplicationDetail.Line1, 35);

      let _data: any = {
        gstStateCode: this.ApplicationDetail.gstStateCode,
        enquiryPurpose: this.ApplicationDetail.enquiryPurpose,
        memberRefNo: this.ApplicationDetail.ApplicationNo,
        firstName: this.ApplicationDetail.Customer_FirstName,
        middleName: "",
        lastName: this.ApplicationDetail.Customer_LastName,
        birthDate: this.ApplicationDetail.DOB,
        gender: this.ApplicationDetail.GenderCode,
        telephoneNumber: this.ApplicationDetail.Customer_PhoneNo,
        addressStateCode: this.ApplicationDetail.address_StateCode,
        pinCode: this.ApplicationDetail.PinCode,

        line1: chunks[0] == undefined ? '' : chunks[0],
        line2: chunks[1] == undefined ? '' : chunks[1],
        line3: chunks[2] == undefined ? '' : chunks[2],
        line4: chunks[3] == undefined ? '' : chunks[3],
        line5: this.splitStringIntoChunks(this.ApplicationDetail.Line2, 35)[0] == undefined ? '' : this.splitStringIntoChunks(this.ApplicationDetail.Line2, 35)[0],
        residenceCode: this.ApplicationDetail.residenceCode,
        enquiryAmount: LoanAmount,
        ids: ids,
      };
      console.log(
        "_data",
        chunks
      );
      //console.log("_data111", _data );

      //this.cibilResponse = { "controlData": { "success": false, "errorResponseArray": [{ "errorCode": "90002", "errorMessage": "Type: icrs; Message: Invalid Enquiry Data          ; Value: PAA010135DHANI CHOULANA KI DHANAWAR BASWA DA" }, { "errorCode": "90002", "errorMessage": "Type: icrs; Message: Invalid Enquiry Data          ; Value: PAA010203USA" }, { "errorCode": "90002", "errorMessage": "Type: icrs; Message: Invalid Enquiry Data          ; Value: PAA01060208" }, { "errorCode": "90002", "errorMessage": "Type: icrs; Message: Invalid Enquiry Data          ; Value: PAA010706303304" }] } }
      //if (this.cibilResponse?.controlData?.success == true) {
      //     //this.cibilResponse = res;
      //  this.error = this.cibilResponse?.controlData?.success
      //      $("#cibilReport").modal("show");
      //      $("#cibilReport").css("z-index", "1050");
      //      this.CibilApiShow = false;
      //    } else {

      //  this.error = this.cibilResponse?.controlData?.success
      //      $("#cibilReport").modal("show");
      //      $("#cibilReport").css("z-index", "1050");
      //      this.CibilApiShow = true;
      //}
      console.log("error", this.error)
      // $("#cibilReport").modal("show");
      //  $("#cibilReport").css("z-index", "1050");
      this.LmsService.FetchCivilData({ loginUserId: this.currentUser.userId, loanId: this.LoanId, CustomerId: this.CustomerId, JSON: _data })
        .subscribe((res: any) => {
          console.log("res", res);
          this.Response = res;
          this.cibilResponse = res;
          console.log("Cibil JSON", this.cibilResponse);
          this.error = this.cibilResponse?.controlData?.success
          if (this.cibilResponse?.controlData?.success == 'true') {
            this.error = this.cibilResponse?.controlData?.success
            $("#cibilReport").modal("show");
            $("#cibilReport").css("z-index", "1050");
            this.CibilApiShow = false;
          }
          else if(this.cibilResponse?.Description=='Invalid API Key'){
            this.snackBar.openFromComponent(SnackbarComponent, {
              data:  this.cibilResponse.Description,
              ...this.configSuccess,
            });
           
          }
          else {

            $("#cibilReport").modal("show");
            $("#cibilReport").css("z-index", "1050");
            this.CibilApiShow = true;
          }

        })
    }
  }
  splitStringIntoChunks(str: string, size: number): string[] {
    let chunks: string[] = [];
    let words = str.split(' ');
    let currentChunk = '';

    for (let word of words) {
      if (currentChunk.length + word.length + 1 <= size) {
        currentChunk += (currentChunk ? ' ' : '') + word;
      } else {
        if (currentChunk) {
          chunks.push(currentChunk);
        }
        currentChunk = word;
      }
    }

    if (currentChunk) {
      chunks.push(currentChunk);
    }

    return chunks;
  }
  onCloseCibilReport() {
    this.OnClose(true);
    $("#cibilReport").modal("hide");
  }
  getCRCThirdDetail() {
    this._MasterService.Get_CibilAPI_Request_Response({ Loan_Id: this.LoanId, CId: this.CustomerId })
      .subscribe((res: any) => {
        if (res.length > 0) {
          this.cibilResponse = JSON.parse(res[0].response);

          console.log("Cibil JSON", this.cibilResponse);
          $("#cibilReport").modal("show");
          $("#cibilReport").css("z-index", "1050");
        }
      })
  }
  CheckCibildecisioncentre() {
    this.showSpinner=true;
    if (this.ApplicationDetail.LoanAmount == undefined || this.ApplicationDetail.LoanAmount == null || this.ApplicationDetail.LoanAmount == 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: "Please fill Account Information!!",
        ...this.configSuccess,
      });
    } else {
      let LoanAmount: string =
        "0".repeat(9 - this.ApplicationDetail.LoanAmount.toString().length) +
        this.ApplicationDetail.LoanAmount.toString();

      var ids = [];
      this.kycList.forEach((value, index) => {
        if (value.Doc_Type_Cibil != "" && value.Doc_Type_Cibil != null) {
          ids.push({
            IdNumber: value.KYC_DocNumber,
            IdType: value.Doc_Type_Cibil,
          });
        }
      });
      var replacementArray = ['!', '#', '$', '%', '&', '*', ',', '/', '?', '@', '\/', ' ^ ', ' | ', '~', ' + ', '='];
      for (let i = 0; i < replacementArray.length; i++) {
        this.ApplicationDetail.Customer_FirstName = this.ApplicationDetail.Customer_FirstName.replace(replacementArray[i], '');
        this.ApplicationDetail.Customer_LastName = this.ApplicationDetail.Customer_LastName.replace(replacementArray[i], '');
        this.ApplicationDetail.Line1 = this.ApplicationDetail.Line1.replace(replacementArray[i], '');
        this.ApplicationDetail.Line2 = this.ApplicationDetail.Line2.replace(replacementArray[i], '');
        ids.forEach((value, index) => {
          value.IdNumber = value.IdNumber.replace(replacementArray[i], '');
        })
      }
      var chunks = this.splitStringIntoChunks(this.ApplicationDetail.Line1, 35);

      let _data: any = {
        gstStateCode: this.ApplicationDetail.gstStateCode,
        enquiryPurpose: this.ApplicationDetail.enquiryPurpose,
        memberRefNo: this.ApplicationDetail.ApplicationNo,
        firstName: this.ApplicationDetail.Customer_FirstName,
        middleName: "",
        email: this.ApplicationDetail.Email == null ? '' : this.ApplicationDetail.Email,
        lastName: this.ApplicationDetail.Customer_LastName,
        birthDate: this.ApplicationDetail.DOB,
        gender: this.ApplicationDetail.Gender,
        telephoneNumber: this.ApplicationDetail.Customer_PhoneNo,
        addressStateCode: this.ApplicationDetail.address_StateCode,
        pinCode: this.ApplicationDetail.PinCode,

        line1: chunks[0] == undefined ? '' : chunks[0],
        line2: chunks[1] == undefined ? '' : chunks[1],
        line3: chunks[2] == undefined ? '' : chunks[2],
        line4: chunks[3] == undefined ? '' : chunks[3],
        line5: this.splitStringIntoChunks(this.ApplicationDetail.Line2, 35)[0] == undefined ? '' : this.splitStringIntoChunks(this.ApplicationDetail.Line2, 35)[0],
        residenceCode: this.ApplicationDetail.residenceCode,
        enquiryAmount: LoanAmount,
        ids: ids,
      };
      this.LmsService.FetchTransunionDecisionCentreData({ loginUserId: this.currentUser.userId, loanId: this.LoanId, CustomerId: this.CustomerId, JSON: _data })
        .subscribe((res: any) => {
          
          if (res.length > 0) {
            this.LmsService
              .FetchTransunionDecisionDocument({ Identifier: res[0].CODE.toString() })
              .subscribe((response: any) => {
                if (response != null) {
                  this.saveAs(response, res[0].MSG);
                  this.blobToBase64Async(response,res[0].MSG);
                 
                 
                }
              });
          }
        });
    }

  }
  uploadData(filename){
  
    this.DocModel1.DocName = filename 
                  this.DocModel1.ApplicationNo = this.loanSummary.ApplicationNo;
               
              this.DocModel1.DocData = this.dataUrl;
           
                 this.losService.UploadCrcDoc(this.DocModel1).subscribe(
                  (res: any) => {
                    console.log("upload success");
                    this.showSpinner=false;
                    this.OnClose(true);

                  })
  }
  saveAs(blob: Blob, fileName: string) {
    console.log("Save as pdf fn");
    const link = document.createElement("a");
    link.download = fileName;
    link.href = window.URL.createObjectURL(blob);
    link.click();
    window.URL.revokeObjectURL(link.href);
  }
  blobToBase64Async(blob: Blob,filename): Promise<string> {

    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      let $this=this;
      fileReader.onerror = (e) => reject(fileReader.error);
      
      fileReader.onload = function () {
        $this.dataUrl = fileReader.result.toString().split(";base64,").pop();
        $this.uploadData(filename);
        return;
      };
      fileReader.readAsDataURL(blob);
    });
  }
  
}
